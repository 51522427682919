import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    save(ctx, data) {
      return new Promise((resolve, reject) => {
        const url = '/configs/save'
        axios
          .put(url, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

  },
}
