<template>
  <b-overlay
    :show="$store.state.app.isContentLoading"
    spinner-variant="primary"
    spinner-type="grow"
    rounded="sm"
  >
    <b-card
      :title="`${$t('Settings')}`"
    >

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>

            <!-- Field: Logo -->
            <b-col
              md="12"
            >
              <!-- Media -->
              <b-media class="mb-2">
                <template #aside>
                  <b-avatar
                    ref="previewEl"
                    :src="configData.business_logo"
                    size="90px"
                    rounded
                  />
                </template>
                <h4 class="mb-1">
                  {{ configData.business_name }}
                </h4>
                <div class="d-flex flex-wrap">
                  <b-button
                    variant="primary"
                    @click="$refs.refInputEl.click()"
                  >
                    <input
                      ref="refInputEl"
                      type="file"
                      class="d-none"
                      @input="inputImageRenderer"
                    >
                    <span class="d-none d-sm-inline">Change</span>
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                </div>
              </b-media>

            </b-col>

            <!-- Field: Name -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="name"
                rules="required"
              >
                <b-form-group
                  :label="$t('Name')"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="configData.business_name"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Phone -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="phone"
                rules="required"
              >
                <b-form-group
                  :label="$t('Phone')"
                  label-for="phone"
                >
                  <b-form-input
                    id="phone"
                    v-model="configData.business_phone"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Website -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="website"
                rules="required"
              >
                <b-form-group
                  label="Website"
                  label-for="website"
                >
                  <b-form-input
                    id="website"
                    v-model="configData.business_website"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Email -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="email"
                rules="required"
              >
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="configData.business_email"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Address -->
            <b-col
              md="4"
            >
              <validation-provider
                #default="validationContext"
                name="address"
                rules="required"
              >
                <b-form-group
                  :label="$t('Address')"
                  label-for="address"
                >
                  <b-form-input
                    id="address"
                    v-model="configData.business_address"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder=""
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <feather-icon icon="SaveIcon" />&nbsp;
              {{ $t('Save') }}
            </b-button>
          </div>

        </b-form>

      </validation-observer>

    </b-card>
  </b-overlay>
</template>

<script>

import {
  BOverlay, BRow, BCol, BCard, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BMedia, BAvatar,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import configStoreModule from './configStoreModule'

export default {
  components: {
    BOverlay,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BMedia,
    BAvatar,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {

  },
  data() {
    return {
      required,
    }
  },
  computed: {
  },
  methods: {

  },
  setup() {
    const STORE_MODULE_NAME = 'config'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, configStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const config = JSON.parse(localStorage.configData)
    const blankData = {
      business_name: config.filter(obj => obj.name === 'business_name')[0].value,
      business_phone: config.filter(obj => obj.name === 'business_phone')[0].value,
      business_website: config.filter(obj => obj.name === 'business_website')[0].value,
      business_email: config.filter(obj => obj.name === 'business_email')[0].value,
      business_address: config.filter(obj => obj.name === 'business_address')[0].value,
      business_logo: config.filter(obj => obj.name === 'business_logo')[0].value,
    }

    const configData = ref(JSON.parse(JSON.stringify(blankData)))

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      configData.value.business_logo = base64
    })

    const onSubmit = () => {
      const data = JSON.parse(JSON.stringify(configData.value))
      store.dispatch('config/save', data)
        .then(response => {
          // save response data to localStorage.configData
          localStorage.setItem('configData', JSON.stringify(response.data.data))
          // console.log(response.data.data)
        })
        .catch(() => {
          // alert('hi')
        })
    }

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      configData,
      onSubmit,

      refFormObserver,
      getValidationState,
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
}
</script>
